import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

export const MySwiper = () => {

//header共通
  const brandSlider = new Swiper('[data-brand-swiper]', {
    //slidesPerView: 3,
    spaceBetween: 0,
    freeMode: true,
    loop: true,
    //loopAdditionalSlides: 2,
    slidesPerView: 'auto',
    navigation: {
      nextEl: '.l-h_brand-slider-next',
      prevEl: '.l-h_brand-slider-prev',
    },
  });

//topメインビジュアル
  const mainVisual = new Swiper('[data-main-swiper]', {
    loop: true,
    effect: 'fade',
    autoplay: {
      delay: 7000,
    },
  });

//しまうまトップ
  const simaumaNote = new Swiper('[data-simauma-note-swiper]', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 20,
    pagination: {
      el: '[data-simauma-note-swiper] .swiper-pagination',
      clickable: true,
    },
    autoplay: {
      delay: 7000,
    },
  });

//汎用
  const swiper = new Swiper('[data-swiper]', {
    slidesPerView: 2,
    spaceBetween: 20,
    freeMode: true,
    loop: true,
    //loopAdditionalSlides: 2,
    navigation: {
      nextEl: '[data-swiper] .swiper-button-next',
      prevEl: '[data-swiper] .swiper-button-prev',
    },
    breakpoints: {
      440: {
        loop: false,
        slidesPerView: 4,
        spaceBetween: 36,
      },
    },
  });

//モーダルスライダー
  const targetOpen = Array.from(
    document.querySelectorAll('[data-open-modal-swiper]'),
  );
  const modalSlider = new Swiper('[data-modal-swiper]', {
    spaceBetween: 60,
    loop: true,
    slidesPerView: 'auto',
    navigation: {
      nextEl: '.m-modal .swiper-button-next',
      prevEl: '.m-modal .swiper-button-prev',
    },
  });
}
