import Vivus from 'vivus';

export const MyVivus = () => {


  const boxes = document.querySelectorAll('[data-vivus-box]');

  boxes.forEach((box) => {
    const current = box.getAttribute('data-vivus-box');
    const duration = box.getAttribute('data-vivus-duration') || '450';
    new Vivus(current, {
      type: 'oneByOne',
      duration: duration,
      animTimingFunction: Vivus.EASE_OUT,
      start: 'inViewport',
    });
  });

}
